import React, {useState} from 'react';
import date from "php-date";
import cn from "classnames";
import {NavLink} from "react-router-dom";
import {ORDERS_STATUS, rolesModerator, ORDERS_STATUS_STYLE} from "utils/config";
import {in_array, number_format, format_phone, diffDays} from "utils/func";

/**
 * Может ли пользователь просматривать заказ
 * @param user
 * @param order
 */
export const canSeeOrder = (user, order) => {
	let canSee = in_array(user.role, rolesModerator);
	if (!parseInt(order.uid))
		canSee = true;
	else if (order.divId == user.divId)
		canSee = true;

	// if (order.onum == 9800) {
	// 	console.dir(order);
	// 	console.log(order.divId +'/'+ user.divId);
	// 	console.log(order.divId == user.divId);
	// }

	return canSee;
};

const OrdersListItem = props => {

	const {
		user,
		order,
		// getPopupEditor,
		isEditable,
		managerSelect, statusSelect,
		columnsTitle,
		setField,//задает значение поля, накладная и примечание
	} = props;
	// console.dir(order);
	// console.log(order.id, canSeeOrder(user, order));

	// const [isEditManager, setEditManager] = useState(false);
	// const [isEditStatus, setEditStatus] = useState(false);
	const [isEditNaklText, setEditNaklText] = useState(false);
	const [isEditPrimText, setEditPrimText] = useState(false);

	let isFieldsEditable = isEditable;
	if (!isEditable && user.id === order.uid)
		isFieldsEditable = true;

	const oStatus = ORDERS_STATUS.get(parseInt(order.status));

	const managerTakeTime = parseInt(order.mdate);

	// console.log(order.odate, diffDays(Date.now(), order.odate * 1000 ));

	return (
		<ul className={cn("tr", {
			userOwn: user.id === order.uid,
			notView: managerTakeTime === 0
		})}>
			<li className={"td col-num"}>
				<div className="td-title">
					{columnsTitle.num}
				</div>
				{
					canSeeOrder(user, order)
						? <NavLink
							to={`/orders/show/${order.id}`}>
							{order.onum}
						</NavLink>
						: order.onum
				}
				{
					!managerTakeTime
					? <div className={"-not-view"} title={"Не просмотрено"}>
						<i className={"fa fa-eye-slash"}></i>
					</div>
					: null
				}
			</li>
			{/*  05/09/22 */}
			<li className={"td col-name"}>
				<div className="td-title">
					{columnsTitle.name}
				</div>
				{order.cName}
			</li>
			<li className={"td col-tel"}>
				<div className="td-title">
					{columnsTitle.tel}
				</div>
				{order.cPhone}
				{/*{format_phone(order.cPhone)}*/}
			</li>
			<li className={"td col-summ"}>
				<div className="td-title">
					{columnsTitle.summ}
				</div>
				{number_format(order.summ)}
			</li>
			<li className={"td col-site"}>
				<div className="td-title">
					{columnsTitle.site}
				</div>
				{order.site}
			</li>
			<li className={"td col-date"}>
				<div className="td-title">
					{columnsTitle.date}
				</div>
				{date('d.m.Y H:i', new Date(order.odate * 1000))}
				<div className="-date-take">
					{
						managerTakeTime
							? date('d.m.Y H:i', new Date(managerTakeTime * 1000))
							: '-'
					}
				</div>

			</li>



			{/*<li className={"td col-date"}>*/}
			{/*	<div className="td-title">*/}
			{/*		{columnsTitle.created}*/}
			{/*	</div>*/}
			{/*	{*/}
			{/*		managerTakeTime*/}
			{/*			? date('d.m.Y H:i', new Date(managerTakeTime * 1000))*/}
			{/*			: '-'*/}
			{/*	}*/}
			{/*</li>*/}

			<li
				className={cn("td col-manager", /*{editable: isEditable}*/)}
				valign={"middle"}

				// onDoubleClick={e => setEditManager(true)}

				title={isEditable ? 'Двойнок клик для редактирования' : ''}
			>
				<div className="td-title">
					{columnsTitle.manager}
				</div>
				<div className={"w100"}>
					{/*{isEditable ? (*/}
					{/*	isEditManager ? managerSelect(order.id, order.uid, setEditManager) : order.userName*/}
					{/*) : (order.userName !== '' ? order.userName : 'не выбран')}*/}

					{isEditable
						? managerSelect(order.id, order.uid, null)
						: (
							order.userName !== ''
								? order.userName
								: 'не выбран'
						)
					}
				</div>
			</li>
			<li
				className={cn("td col-status", /*{editable: isEditable}*/)}
				// onDoubleClick={e => setEditStatus(true)}
				style={ORDERS_STATUS_STYLE.get(order.status) ?? null}
			>
				<div className="td-title">
					{columnsTitle.status}
				</div>
				{/*{isEditable ? (*/}
				{/*	isEditStatus ? statusSelect(order.id, order.status, setEditStatus) : oStatus*/}
				{/*) : oStatus}*/}
				{isEditable ? statusSelect(order.id, order.status, null, order.odate) : oStatus}

				{
					(order.status === 5 && diffDays(Date.now(), order.odate * 1000 ) >= 4)
						? <div className={"orders-call"}>
							Обзвонить
						</div>
						: null
				}

			</li>

			<li
				className={cn("td col-nakl", {editText: isFieldsEditable})}
				// onDoubleClick={e => isEditable && getPopupEditor('nakl', order.id, order.nakl)}
			>
				<div className="td-title">
					{columnsTitle.nakl}
				</div>
				<div className={cn("editor", {notEdit: !isEditNaklText})}
					 contentEditable={(isFieldsEditable && isEditNaklText)}
					 // contentEditable={(isFieldsEditable)}
					 suppressContentEditableWarning={true}
					 onBlur={e => {
						 setEditNaklText(false);

						 if (order.nakl != e.currentTarget.innerHTML)
						 	setField(order.id, 'nakl', e.currentTarget.innerHTML);

					 }}
					 // onClick={e => setEditNaklText(true)}
					 onMouseEnter={e => setEditNaklText(true)}
					 // onMouseLeave={e => setEditNaklText(false)}
				>
					{order.nakl}
				</div>

			</li>
			<li
				className={cn("td col-prim", {editText: isFieldsEditable})}
				// onDoubleClick={e => isEditable && getPopupEditor('prim', order.id, order.prim)}
			>
				<div className="td-title">
					{columnsTitle.prim}
				</div>
				<div className={cn("editor", {notEdit: !isEditPrimText})}
					 contentEditable={(isFieldsEditable && isEditPrimText)}
					 suppressContentEditableWarning={true}
					 onBlur={e => {
					 	setEditPrimText(false);

						 if (order.prim != e.currentTarget.innerHTML)
					 		setField(order.id, 'prim', e.currentTarget.innerHTML);
					 }}
					 onMouseEnter={e => setEditPrimText(true)}
					 // onMouseLeave={e => setEditPrimText(false)}
					 // onClick={e => setEditPrimText(true)}
				>
					{order.prim !== '' ? order.prim : ''}
				</div>
			</li>
		</ul>
	);
};

export default OrdersListItem;

