import React, {useEffect, useState, useRef} from 'react';
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";
import {useParams} from "react-router-dom";
import {setCalendarSelectDateAction, setSearchAction} from "actions/GUIActions";
import Api, {apiAction} from "utils/Api";
import {ContentHeader, Button} from "components/_common";
import {rolesModerator, rolesAdmin, MENU_TITLE, SKIP_SEARCH_ACTION_NAME, ORDERS_STATUS, MESSAGES} from "utils/config";
import {in_array, spreadByDivisions, number_format, user4api} from "utils/func";
import OrdersList from "components/orders/OrdersList";
//import OrdersListItem from "components/orders/OrdersListItem";
import {saveSync} from "save-file";


const API_URL = 'orders.php';

const OrdersContainer = props => {

    const {user, GUI, actions} = props;

    let {status = '', site = ''} = useParams();

    if (site != '')
        status = '';
    // console.log(status, site);


    const selectedManagerId = useRef('0');
    const setSelectedManagerId = id => {
        selectedManagerId.current = id;
        // Storage.set('selectedManagerId', id);
    };
    const selectedStatus = useRef(-1);
    const selectedDomain = useRef('');

    const [managers, setManagers] = useState([]);
    const [orders, setOrders] = useState([]);
    const [showOrders, setShowOrders] = useState([]);
    const [divisions, setDivisions] = useState([]);

    let ordersByDivision = null;

    // const [popupWin, setPopupWin] = useState(null);


    let apiActionProcess = false;
    const getData = async (isSubscribe = true, calDates = null, search = '') => {
        // console.clear();
        // console.log(calDates);
        // console.log(GUI.calendarDates);
        if (!isSubscribe)
            return;

        console.log('orders getData');

        if (!apiActionProcess) {
            actions.showLoading();
            apiActionProcess = true;

            const data = {
                action: 'getOrders',
                userId: user.id,
                dates: calDates ? calDates : GUI.calendarDates,
                status: status,
                search: search,
                site: site
            };

            console.dir(data);

            const res = await Api.post(API_URL, data);

            apiAction(res, (response) => {

                setOrders(response.orders);
                setManagers(response.managers);
                setDivisions(response.divisions);
                setShowOrders(
                    filterOrders(
                        response.orders,
                        selectedManagerId.current,
                        selectedStatus.current,
                        selectedDomain.current
                    )
                );
            }, () => {
                apiActionProcess = false;
                actions.hideLoading();
            });
            apiActionProcess = false;
        }
        actions.hideLoading();
    };

    const onCalendarSelectDate = (dates) => {
        getData(true, dates).then(null);
    };


    const searchFilterRef = useRef();


    const doSearch = str => {
        let isSkipSearch = str === SKIP_SEARCH_ACTION_NAME;
        let search = str;
        let searchFilterYear = parseInt(searchFilterRef.current.value);

        if (!isSkipSearch && !isNaN(searchFilterYear))
            search = {
                str: str,
                dateStart: new Date(searchFilterYear, 0, 1, 0, 0, 0),
                dateEnd: new Date(searchFilterYear, 11, 31, 23, 59, 59)
            };

        // let searchDates = null;
        //
        // let searchFilterYear = parseInt(searchFilterRef.current.value);
        // if (!isNaN(searchFilterYear)) {
        //     searchDates = {
        //         start: new Date(searchFilterYear, 0, 1, 0, 0, 0),
        //         end: new Date(searchFilterYear, 11, 31, 23, 59, 59)
        //     };
        // }
        // if (isSkipSearch)
        //     searchDates = null;



        getData(
            true,
            null,
            search
        ).then(null);
    };

    let sfyOptions = [];
    // let nowYear = (new Date()).getFullYear();
    for (let y = (new Date()).getFullYear(); y >= 2015; y--)
        sfyOptions.push(<option key={`sfy-${y}`} value={y}>{y}</option>);

    const searchFilter = (
        <select
            name={"search-filter-year"}
            defaultValue={''}
            ref={searchFilterRef}
        >
            <option key={"sfy-0"} value={''}>все года</option>
            {sfyOptions}
        </select>
    );

    // console.log('OC RENDER');
    useEffect(() => {
            let isSubscribe = true;
            getData(isSubscribe).then(null);
            actions.setCalendarSelectDateAction(onCalendarSelectDate);
            // actions.setSearchAction(doSearch);
            actions.setSearchAction({action: doSearch, filter: searchFilter});

            if (GUI.socket)
                GUI.socket.on('serverHasNewData', res => {
                    // console.log('ORDERS LIST serverHasNewData', res);
                    if (res.newOrdersCount !== undefined)
                        getData(isSubscribe).then(null);
                });

            return () => {
                isSubscribe = false;
                //отключаем функцию обработчик на календаре
                actions.setCalendarSelectDateAction(null);
                actions.setSearchAction(null);
                // setPopupWin(null);
            }
        },
        // [status, GUI.socket]
        [status, site, ordersByDivision]
    );

    const printAction = () => {
        window.print();
    };

    const setField = async (id, field, value, setField = true) => {
        // console.clear();
        // console.log('setField', field, value);
        if (!apiActionProcess) {
            actions.showLoading();
            apiActionProcess = true;

            const res = await Api.post(API_URL, {
                action: 'setField',
                userId: user.id,
                id: id,
                field: field,
                value: value
            });

            apiAction(res, (data) => {
                // console.log(setField);
                // console.log(data);
                if (setField) {
                    let _orders = [...showOrders];

                    _orders.map((order, key) => {
                        if (order.id === id) {
                            _orders[key][field] = data.value;

                            if (data.status != -1)
                                _orders[key]['status'] = data.status;
                        }
                        return null;
                    });

                    // console.dir(_orders);

                    setShowOrders(_orders);

                }


            }, () => {
                apiActionProcess = false;
                actions.hideLoading();
                // setPopupWin(null);
            });

            apiActionProcess = false;
        }
    };

    // const popUpWinFieldRef = createRef();
    //
    // const getPopupEditor = (field, formId, value) => {
    //
    // 	let title, fieldType;
    // 	if (field === 'nakl') {
    // 		title = 'Номер накладной';
    // 		fieldType = 'text';
    // 	}
    // 	else {
    // 		title = 'Примечание';
    // 		fieldType = 'txt';
    // 	}
    //
    // 	setPopupWin(
    // 		<div className="popup-win">
    // 			<Win
    // 				header={"Редактировать"}
    // 				onClose={e => setPopupWin(false)}
    // 				footer={
    // 					(
    // 						<div className={"win-ftr-btns _tac"}>
    // 							<Button type="apply" onClick={e => {
    // 								setField(formId, field, popUpWinFieldRef.current.value).then(null);
    // 							}}/>
    // 						</div>
    // 					)
    // 				}
    // 				winClass={"half"}
    // 				>
    // 				<FormItem
    // 					name={"txt"}
    // 					label={title}
    // 					fieldType={fieldType}
    // 					defVal={value}
    // 					reff={popUpWinFieldRef}
    // 				/>
    // 			</Win>
    // 		</div>
    // 	);
    // };

    const filterOrders = (data, managerId, status, selectedDomain) => {
        // console.log(`filterOrders managerId=${managerId} status=${status}  selectedDomain=${selectedDomain}`);
        if (!data.length)
            return [];

        managerId = parseInt(managerId);

        let _orders = [];
        data.map(order => {
            let valid = true;

            if (managerId !== 0 && parseInt(order.uid) !== managerId)
                valid = false;

            if (status >= 0 && order.status != status)
                valid = false;

            if (selectedDomain != '' && order.site != selectedDomain)
                valid = false;

            if (valid)
                _orders.push(order);

            // console.log(order.id + '/uid=' + order.uid + '/status=' + order.status + '/' + `site=${order.site} valid=` + valid);
            return null;
        });

        return _orders;
    };
    const dumpOrdersDB = async () => {
        if (!apiActionProcess) {
            actions.showLoading();
            apiActionProcess = true;

            const res = await Api.post(API_URL, {
                action: 'dumpOrdersTable',
                userId: user.id
            });

            apiAction(res, (data) => {
                saveSync(
                    data.fileData,
                    data.fileName
                );
            }, () => {
                apiActionProcess = false;
                actions.hideLoading();
            });
        }
    };




    let allDomains = [];
    divisions.map(division => {
        if (division.domains.length > 0) {
            if (in_array(user.role, rolesModerator))
                allDomains = allDomains.concat(division.domains);
            else if (user.divId == division.id)
                allDomains = division.domains;
        }
        return null;
    });
    allDomains.sort();



    if (showOrders && showOrders.length && divisions) {
        ordersByDivision = spreadByDivisions(divisions, showOrders);
    }
    // console.dir('ordersByDivision', ordersByDivision);


    const panelDoFilter = () => {
        setShowOrders(
            filterOrders(
                orders,
                selectedManagerId.current,
                selectedStatus.current,
                selectedDomain.current
            )
        );
    }

    let statusOptions = [];
    ORDERS_STATUS.forEach((title, s) => {
        statusOptions.push(
            <option
                key={"status-" + s}
                value={s}>
                {title}
            </option>
        )
    });

    const panel = (
        <div className="panel-top mt20m orders no-print">

            <div className="-sum-info mt20 flx ">
                {
                    ordersByDivision != null ? divisions.map(division => {
                        if (!ordersByDivision[division.id])
                            return null;

                        return (
                            <div key={"osi-" + division.id}>
                                <p>{division.name}</p>
                                Кол-во: {ordersByDivision[division.id].items.length} шт<br/>
                                Сумма: {number_format(ordersByDivision[division.id].totalSum)}руб.
                            </div>
                        );
                    }) : null
                }
            </div>

            <div className="flx -sb">
                <div className="row mt20 -filters">
                    {(allDomains && allDomains.length > 1) ? (
                        <div className="col">
                            <select
                                name={"selectedDomain"}
                                defaultValue={selectedDomain.current}
                                onChange={e => {

                                    selectedDomain.current = e.target.value;
                                    panelDoFilter();
                                    // setShowOrders(
                                    // 	filterOrders(
                                    // 		orders,
                                    // 		selectedManagerId.current,
                                    // 		selectedStatus.current,
                                    // 		selectedDomain.current
                                    // 	)
                                    // );

                                }}

                            >
                                <option key={"site-0"} value={""}>все сайты</option>
                                {
                                    allDomains.map(domain => {
                                        return <option key={"site-" + domain} value={domain}>{domain}</option>
                                    })
                                }
                            </select>
                        </div>
                    ) : null}
                    {(managers && managers.length) ? (
                        <div className="col">
                            <select
                                name={"sortManagerId"}
                                defaultValue={selectedManagerId}
                                onChange={e => {
                                    setSelectedManagerId(e.target.value);
                                    panelDoFilter();
                                }}

                            >
                                <option key={"manager-0"} value={0}>все менеджеры</option>
                                {
                                    managers.map(man => {
                                        return <option key={"manager-" + man.id} value={man.id}>{man.name}</option>
                                    })
                                }
                            </select>
                        </div>
                    ) : null}
                    <div className="col">
                        {
                            <select
                                name={"sortStatus"}
                                defaultValue={selectedStatus.current}
                                onChange={e => {
                                    selectedStatus.current = e.target.value;
                                    panelDoFilter();
                                    // setShowOrders(
                                    // 	filterOrders(orders, selectedManagerId.current, selectedStatus.current)
                                    // );

                                }}
                            >
                                <option key={"manager-0"} value={null}>все статусы</option>
                                {statusOptions}
                            </select>
                        }
                    </div>
                </div>
                <div className="mt20 no-print -btns">
                    {
                        in_array(user.role, rolesModerator)
                        && <Button
                            title={"DUMP таблицы заказов"}
                            ico={"download"}
                            onClick={dumpOrdersDB}
                            cls={"-lgrey"}
                        />

                    }

                    <Button
                        title={"Распечатать"}
                        ico={"print"}
                        onClick={printAction}
                        // type={""}
                        cls={"-lgrey"}
                    />
                </div>
            </div>
        </div>
    );


    // const isEditable = in_array(user.role, rolesModerator);
    let isEditable = in_array(user.role, [...rolesModerator, 'manager']);
    // console.log(user.role);
    // console.log(isEditable);

    // console.dir(managers);

    const managerSelect = (orderId, managerId, whenSelectAction) => {
        return (
            <select
                name={"manId"}
                key={"man-select-" + orderId}
                defaultValue={managerId}
                onChange={e => {
                    const selectedManagerId = e.target.value;

                    let _orders = [...showOrders];

                    _orders.map((order, key) => {

                        if (order.id === orderId) {
                            _orders[key].uid = selectedManagerId;
                            managers.map(man => {
                                if (man.id === selectedManagerId)
                                    _orders[key].userName = man.name;
                            });

                            if (!parseInt(selectedManagerId))
                                _orders[key].userName = 'не выбран';

                            _orders[key].mdate = Date.now() / 1000;

                        }
                    });

                    setField(orderId, 'uid', selectedManagerId, true).then(() => {

                        //отправка события, чтобы пересчитались новые данные и отобразились у всех
                        if (GUI.socket)
                            GUI.socket.emit(
                                'setOpenByUser',
                                {
                                    userId: selectedManagerId,
                                    target: 'order'
                                }
                            );

                        // console.dir(_orders);
                        //
                        setShowOrders(_orders);
                        // ordersByDivision = null;
                        // getData(true).then(null);
                    });
                }}
                // onFocus={e => {
                // 	whenSelectAction(true);
                // }}
                // onBlur={e => {
                // 	// whenSelectAction(false);
                // }}
            >
                <option key={"manager-0"} value={0}>не выбран</option>
                {
                    managers.map(man => {
                        return <option key={"manager-" + man.id} value={man.id}>
                            {man.name}
                            {/*{*/}
                            {/*	man.divName ? ` (${man.divName})` : null*/}
                            {/*}*/}
                        </option>
                    })
                }
            </select>
        );
    };



    const statusSelect = (orderId, status, whenSelectAction, orderDatetime) => {
        // console.log(orderId, status);

        let defStatus = status;
        let style = null;
        // if (status == 0 && ((orderDatetime * 1000) <= (Date.now() - 3 * 86400000))) {
        //     style = {color: '#fff', backgroundColor: 'red'};
        //     defStatus = 20;
        // }
        // console.log(defStatus);

        return (
            <>
            <select
                name={"status"}
                key={"status-select-" + orderId}
                value={defStatus}
                // defaultValue={defStatus}
                onChange={e => {
                    setField(orderId, 'status', e.target.value, true).then(null);
                }}
                style={style}
                // onFocus={e => {
                // 	whenSelectAction(true);
                // }}
                // onBlur={e => {
                // 	whenSelectAction(false);
                // }}
            >
                {statusOptions}
            </select>
                </>
        );
    };


    let chParent = [];
    let chTitle = MENU_TITLE.orders.many;
    let chTitleEnds = '';
    if (status != '' && ORDERS_STATUS[status]) {
        chTitle = ORDERS_STATUS[status];
        chParent = [
            {
                url: '/orders',
                name: MENU_TITLE.orders.many
            }
        ]
    }
    if (selectedManagerId.current !== '0') {
        managers.map(man => {
            if (man.id === selectedManagerId.current)
                chTitleEnds = (<span className={"print-only"}>, менеджер {man.name}</span>);
        });

    }

    const columnsTitle = {
        num: '№ заказа',
        name: 'Контактное лицо',
        tel: 'Телефон',
        date: 'Дата заказа / Время приемки',
        // created: 'Время приемки',
        manager: 'Менеджер',
        status: 'Статус',
        site: 'Сайт',
        nakl: '№ накл.',
        prim: 'Примечание',
        summ: 'Сумма, руб.'
    };

    // if (user && !in_array(user.role, rolesAdmin) && parseInt(user.divId) !== 4)
    // 	return <div className="">
    // 		{MESSAGES.accessDenied}
    // 	</div>;
    // console.clear();
    // console.dir(showOrders);
    // console.dir(divisions);


    // console.dir(ordersByDivision);


    return (
        <>
            <ContentHeader
                title={chTitle}
                showDates={true}
                datesAction={getData}
                parent={chParent}
                titleEnds={chTitleEnds}
            />
            {panel}
            {
                (showOrders && showOrders.length) ? (
                    <div className="orders-tbl tbl dark hdr-cntr mt20 -bordered">
                        <div className="thead">
                            <ul className={"tr"}>
                                <li className={"td col-num"}>
                                    {columnsTitle.num}
                                </li>
                                <li className={"td col-name"}>
                                    {columnsTitle.name}
                                </li>
                                <li className={"td col-tel"}>
                                    {columnsTitle.tel}
                                </li>
                                <li className={"td col-summ"}>
                                    {columnsTitle.summ}
                                </li>
                                <li className={"td col-site"}>
                                    {columnsTitle.site}
                                </li>
                                <li className={"td col-date"}>
                                    {columnsTitle.date}
                                </li>

                                {/*<li className={"td col-date"}>*/}
                                {/*	{columnsTitle.created}*/}
                                {/*</li>*/}
                                <li className={"td col-manager"}>
                                    {columnsTitle.manager}
                                </li>
                                <li className={"td col-status"}>
                                    {columnsTitle.status}
                                </li>

                                <li className={"td col-nakl"}>
                                    {columnsTitle.nakl}
                                </li>
                                <li className={"td col-prim"}>
                                    {columnsTitle.prim}
                                </li>
                            </ul>
                        </div>
                        <div className="tbody">
                            {divisions.map(division => {
                                if (ordersByDivision[division.id])
                                    return <OrdersList
                                        division={division}
                                        ordersInfo={ordersByDivision[division.id]}

                                        key={"orders-list-" + division.id}
                                        user={user}
                                        columnsTitle={columnsTitle}
                                        isEditable={isEditable}

                                        managerSelect={managerSelect}
                                        selectedManagerId={selectedManagerId.current}

                                        statusSelect={statusSelect}
                                        setField={setField}
                                    />
                            })}

                            {
                                ordersByDivision[0].items.length
                                    ? <OrdersList
                                        division={{id: 0, name: 'Не определено'}}
                                        ordersInfo={ordersByDivision[0]}

                                        key={"orders-list-0"}
                                        user={user}
                                        columnsTitle={columnsTitle}
                                        isEditable={isEditable}

                                        managerSelect={managerSelect}
                                        selectedManagerId={selectedManagerId.current}

                                        statusSelect={statusSelect}
                                        setField={setField}
                                    />
                                    : null
                            }
                            {/*{showOrders.map(order => {*/}
                            {/*										return <OrdersListItem*/}
                            {/*			user={user}*/}
                            {/*			key={"orders-list-item-" + order.id}*/}
                            {/*			order={order}*/}
                            {/*			// getPopupEditor={getPopupEditor}*/}
                            {/*			isEditable={isEditable}*/}
                            {/*			managerSelect={managerSelect}*/}
                            {/*			statusSelect={statusSelect}*/}
                            {/*			status={status}*/}
                            {/*			selectedManagerId={selectedManagerId.current}*/}
                            {/*			columnsTitle={columnsTitle}*/}
                            {/*			setField={setField}*/}
                            {/*		/>*/}
                            {/*})}*/}
                        </div>
                    </div>
                ) : null
            }
            {/*{popupWin}*/}
        </>
    );


};

const mapStateToProps = store => ({
    user: store.user,
    GUI: store.GUI
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        showLoading, hideLoading, setCalendarSelectDateAction, setSearchAction
    }, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(OrdersContainer);
