import React from 'react';
import {Button} from "components/_common";
import {NavLink} from "react-router-dom";
// import {saveSync} from "save-file";
// import {toastr} from "react-redux-toastr";
// import {getFileSize} from "utils/func";




const Installers = ({data, downloadFile}) => {

	if (data === null)
		return <div>
			Выберите даты и нажмите кнопку "применить"
		</div>;


	const installersData = data.data ? Object.values(data.data) : [];

	// console.dir(installersData);
	// const downloadExcelFile = () => {
	// 	if (data.fileData == undefined || data.fileData == null) {
	// 		toastr.error(
	// 			'Ошибка',
	// 			'Данные файла не найдены'
	// 		);
	// 		return;
	// 	}
	// 	if (data.fileName == undefined || data.fileName == null) {
	// 		toastr.error(
	// 			'Ошибка',
	// 			'Неизвестно наименование файла'
	// 		);
	// 		return;
	// 	}
	// 	saveSync(
	// 		data.fileData,
	// 		data.fileName
	// 	);
	//
	// };


	if (installersData.length == 0)
		return <div>
			Монтажи не найдены
		</div>;

	return (
		<>
			<div className="panel-top flx -e mt20m orders no-print">
				<div className="mt20 row">
					<div className="">
						<Button
							title={"Скачать Excel-файл"}
							ico={"file-excel-o"}
							onClick={() => downloadFile()}
							type={"save"}
							// cls={"-lgrey"}
						/>
					</div>
				</div>
			</div>
			{
				installersData.map((item, i) => {
					return <InstallerTable
						key={'inst-tbl-' + i}
						data={item}
						iter={i}
					/>
				})
			}
		</>
	)

};

export default Installers;

const InstallerTable = ({data, iter}) => {


	// console.dir(data);
	let tasks = data.tasks ? Object.values(data.tasks) : [];
	// console.dir(tasks);
	tasks = sortTasks(tasks);

	return (
		<div className="installers-report-tbl tbl hdr-cntr dark">
			<div className="thead">
				<div className="-tit">
					<strong>{data.name}</strong>
				</div>
				<div className="tr">
					<div className="td -num">
						№
					</div>
					<div className="td -date">
						Дата монтажа
					</div>
					<div className="td -nakl">
						Накладные
					</div>
					<div className="td -ttl">
						Кол-во монтажников
					</div>
					<div className="td -ttl">
						Разгрузка
						сумма в накл.
					</div>
					<div className="td -ttl">
						Монтаж подстветки,
						сумма в накл.
					</div>
					<div className="td -ttl">
						Сумма монтажа в накл
					</div>
					<div className="td -lnk">
						&nbsp;
					</div>
				</div>
			</div>
			<div className="tbody">
				{
					tasks.map((item, i) => {
						let key = `inst-tbl-task-${iter}-${i}`;
						return <div className="tr" key={key}>
							<div className="td -num">
								{(i + 1)}
							</div>
							<div className="td -date">
								{item.date}
							</div>
							<div className="td -nakl">
								{item.nakl}
								{/*{item.taskId}*/}
							</div>
							<div className="td -ttl">
								{item.total}
							</div>
							<div className="td -ttl">
								{item.priceUnload}
							</div>
							<div className="td -ttl">
								{item.priceLights}
							</div>
							<div className="td -ttl">
								{item.priceInstall}
							</div>
							<div className="td -lnk">
								{
									item.completeDate > 0
										? <NavLink
											key={key + 'inst'}
											to={"/tasks/install/" + item.taskId}>
										монтаж
										</NavLink>
										: null

								}
							</div>
						</div>;
					})
				}
			</div>

		</div>
	);
}
const sortTasks = tasks => {
	return tasks.sort((a, b) => {
		let res = 0;
		let ax = parseInt(a.dateUnix);
		let bx = parseInt(b.dateUnix);

		if (ax < bx)
			return -1;
		else if (ax > bx)
			return 1;

		return res;
	});
}
