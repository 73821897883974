import React, {forwardRef, Fragment, useEffect} from 'react';
import cn from "classnames";
import {TasksListTableRow} from  "components/tasks";
import {sortTasks} from './utils';
// import {logger} from "redux-logger/src";
// import {NavLink} from "react-router-dom";
// import * as strtotime from "strtotime";
// import date from "php-date";
// import {rolesAdmin, rolesModerator, SITE_URL} from "utils/config";
import {get_noun} from "utils/func";
import $ from "jquery";


const TasksList = props => {

	const {user, GUI,
		onSave, onSendSms,
		showTasks: tasks = [],
		users = {},
		todoList = {}, divisions = {},
		drivers = {}, installers = {}, installersLimit = 0,
		todoDostavkaCode = [], todoInstallCode = [], todoTwoInstaller = [],
		showPopup,
		setField
	} = props;
	// console.dir(tasks);

	// let maxHeightBlocks = [];

	const listHalfedBlocks = (setHeight = false, maxHeightBlocks = []) => {
		let row = 0;
		$('.tbody .tr').each(function(){
			// console.log(row);
			if (!setHeight)
				maxHeightBlocks[row] = [];
			let column = 0;
			$(this).find('.half-cont').each(function(){
				// let i = $(this).index();
				// console.log(row, column);
				// $(this).css('background', '#ccc');
				if (!setHeight)
					maxHeightBlocks[row][column] = [];

				$(this).children('div').each(function(){
					let i = $(this).index();

					if (setHeight) {
						let newHeight = 0;
						maxHeightBlocks[row].map(_col => {
							// console.log(_col);
							if (_col[i] !== undefined && _col[i] > newHeight)
								newHeight = _col[i];
							// _col.map(_h => {
							//
							// });

							// if (_h > newHeight)
							// 	newHeight = _h;
						});
						$(this).height(newHeight);
					}
					else {
						let h = $(this).outerHeight();
						// if (maxHeightBlocks[row][column][i] < h)
							maxHeightBlocks[row][column][i] = h;
					}
					// console.log(row, column, i, h);

					// $(this).css('background', '#ccc');
				});

				column++;
			});

			row++;
		});
		return maxHeightBlocks;
	}


	useEffect(() => {
		// console.clear();
		//
		//
		//
		// let maxHeightBlocks = listHalfedBlocks(false);
		// console.dir(maxHeightBlocks);
		// listHalfedBlocks(true, maxHeightBlocks);
		// console.log(123);
		// setTimeout(() => {
		// 	let maxHeightBlocks = listHalfedBlocks(false);
		// 	// console.dir(maxHeightBlocks);
		// 	listHalfedBlocks(true, maxHeightBlocks);
		// }, 1500);

		$(document).ready(function(){
			// console.log('ready');
			let maxHeightBlocks = listHalfedBlocks(false);
			// console.dir(maxHeightBlocks);
			listHalfedBlocks(true, maxHeightBlocks);
		});

	}, []);


	if (!tasks.length)
		return null;



	const {groupSnabjenie = [],
		groupPeremeshenie = [],
		//для кладовщика
		groupKladovshik = [],
		//для закупок - выводиться в отдельном разделе
		// groupBuy		= [],
		//массив массивов по разным офисам
		divisionsGroups = {},
		total = 0,
		totalDrivers = 0,
		totalInstallers = 0,
		expectedTaskInstallers = 0
	} = sortTasks(tasks, user, divisions, users);

	// console.log(tasks);
	// tasks.map(task => {
	// 	if (task.id == 27040)
	// 		console.dir(task);
	// })


	const columnsTitle = {
		ndate: <>№ <br /> Дата</>,
		company: 'Компания',
		op: <>Контакты / Описание</>,
		contacts: 'Адрес',
		address: <>Пометки</>,
		execs: <>Водитель <br /> Монтажники</>
	};


	let TABLE_ROW_NUM = 0;



	return (
		<div className="mt20">
			<div className="row">
				<div className="col-2">
					Всего: {total}
				</div>
				<div className="col-2 flx -e">
					Назначено {totalInstallers} монтажник{get_noun(totalInstallers, '', 'а', 'ов')} из {expectedTaskInstallers}
				</div>
			</div>
			<div className={"sklad-tasks-list  tbl hdr-cntr -bordered role-" + user.role}>
				<TableHeader columnsTitle={columnsTitle}/>
				<div className="tbody">
					{
						groupKladovshik.length ? (
							<>
								<TableSubHeader title={"Кладовщику"} />
								<>
									{
										groupKladovshik.map(task => {
											TABLE_ROW_NUM++;
											return <TasksListTableRow
												user={user}
												GUI={GUI}
												onSave={onSave}
												onSendSms={onSendSms}
												columnsTitle={columnsTitle}

												task={task}
												key={task.id}

												todoList={todoList}
												installers={installers}
												drivers={drivers}
												todoDostavkaCode={todoDostavkaCode}
												todoInstallCode={todoInstallCode}
												todoTwoInstaller={todoTwoInstaller}
												TABLE_ROW_NUM={TABLE_ROW_NUM}
												showPopup={showPopup}
												onSetField={setField}
											/>
										})
									}
								</>
							</>
						) : null
					}
					{
						groupSnabjenie.length ? (
							<>
								<TableSubHeader title={"Снабжение"} />
								<>
									{
										groupSnabjenie.map(task => {
											TABLE_ROW_NUM++;
											return <TasksListTableRow
												user={user}
												GUI={GUI}
												onSave={onSave}
												onSendSms={onSendSms}
												columnsTitle={columnsTitle}

												task={task}
												key={task.id}

												todoList={todoList}
												installers={installers}
												drivers={drivers}
												todoDostavkaCode={todoDostavkaCode}
												todoInstallCode={todoInstallCode}
												todoTwoInstaller={todoTwoInstaller}
												TABLE_ROW_NUM={TABLE_ROW_NUM}
												showPopup={showPopup}
												onSetField={setField}
											/>
										})
									}
								</>
							</>
						) : null
					}

					{
						groupPeremeshenie.length ? (
							<>
								<TableSubHeader title={"Перемещения"} />
								<>
									{
										groupPeremeshenie.map(task => {
											TABLE_ROW_NUM++;
											return <TasksListTableRow
												user={user}
												GUI={GUI}
												onSave={onSave}
												onSendSms={onSendSms}
												columnsTitle={columnsTitle}

												task={task}
												key={task.id}

												todoList={todoList}
												installers={installers}
												drivers={drivers}
												todoDostavkaCode={todoDostavkaCode}
												todoInstallCode={todoInstallCode}
												todoTwoInstaller={todoTwoInstaller}

												peremeshenie={true}
												TABLE_ROW_NUM={TABLE_ROW_NUM}
												showPopup={showPopup}
												onSetField={setField}
											/>
										})
									}
								</>
							</>
						) : null
					}
					{
						Array.isArray(divisionsGroups)
							? divisionsGroups.map(division => {

								return (
									Object.keys(division.forUser).map(uid => {
										return (
											<Fragment key={"by-div-" + division.id + uid}>
												<TableSubHeader
													key={"by-div-th-" + division.id + uid}

													title={division.name +' - '+division.forUser[uid].name}
												/>

												{
													division.forUser[uid].tasks.map(task => {
														TABLE_ROW_NUM++;
														return <TasksListTableRow
															key={'TasksListTableRow-'+division.id + '_'+ uid + '_' + task.id}
															user={user}
															GUI={GUI}
															onSave={onSave}
															onSendSms={onSendSms}
															columnsTitle={columnsTitle}

															task={task}
															todoList={todoList}
															installers={installers}
															drivers={drivers}
															todoDostavkaCode={todoDostavkaCode}
															todoInstallCode={todoInstallCode}
															todoTwoInstaller={todoTwoInstaller}
															users={users}
															TABLE_ROW_NUM={TABLE_ROW_NUM}
															showPopup={showPopup}
															onSetField={setField}
														/>
													})
												}
											</Fragment>
										)
									})
								)
							}) : null

					}
				</div>
			</div>
		</div>
	);




};

export default TasksList;



const TableSubHeader = props => {

	return (
		<div className="sub-hdr">
			{props.title}
		</div>
	)
};
const TableHeader = props => {

	const {columnsTitle} = props;

	return (
		<div className="thead">
			<ul className="tr">
				<li className="td col-num">
					{columnsTitle.ndate}
				</li>
				<li className="td col-company">
					{columnsTitle.company}
				</li>
				{/*<li className="td col-comp">*/}
				{/*	{columnsTitle.company}*/}
				{/*</li>*/}
				{/*<li className="td col-op">*/}
				{/*	{columnsTitle.op}*/}
				{/*</li>*/}
				{/*<li className="td col-cont">*/}
				{/*	{columnsTitle.contacts}*/}
				{/*</li>*/}
				<li className="td col-adr">
					{columnsTitle.address}
				</li>
				<li className="td col-execs">
					{columnsTitle.execs}
				</li>
				<li className="td -poseshen">
					{<span>&nbsp;</span>}
				</li>
			</ul>
		</div>
	);
};
